<template>
  <div class="my-4">
    <div class="card border-0 shadow-sm">
      <div class="card-body text-dark" style="font-weight: bold">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3 class="text-primary px-2 text-md-center flex-fill m-0">
            Profile
          </h3>
          <router-link
            to="/users"
            tag="a"
            class="text-secondary text-decoration-none ms-auto"
            ><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Go
            Back</router-link
          >
        </div>
        <!-- Details View -->
        <div
          class="p-0 m-0 d-flex justify-content-between flex-md-row flex-column align-items-center"
        >
          <div class="p-0 m-0 order-md-0 order-2">
            <h2
              class="py-3"
              v-text="
                nameFormatter(
                  reqObj.first_name,
                  reqObj.middle_name,
                  reqObj.last_name
                )
              "
            ></h2>
            <h6 class="py-1" v-if="reqObj.p_email">
              Personal email:{{ reqObj.p_email }}
            </h6>
            <h6 class="py-1" v-if="reqObj.o_email">
              Office email: {{ reqObj.o_email }}
            </h6>
            <h6 class="py-1" v-if="reqObj.designation_id">
              Designation: {{ getDesignation(reqObj.designation_id) }}
            </h6>
          </div>
          <div class="p-0 m-0 order-1 order-md-0">
            <div
              class="position-absolute text-center rounded-circle bg-light d-flex align-items-center justify-content-center"
              id="profile-hover"
              v-if="isEditable"
              @click="imageModalController()"
            >
              <i class="fa fa-camera fs-2 text-dark"></i>
            </div>
            <!-- <img
              v-if="reqObj.image_url"
              :src="reqObj.image_url"
              class="ml-auto rounded-circle"
              width="150"
              height="150"
            /> -->
            <div
              v-if="reqObj.image_url"
              :style="{
                height: '150px',
                width: '150px',
                background: `url(${reqObj.image_url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }"
              class="rounded-circle ml-auto"
            ></div>
            <img
              v-else
              src="../img/user.svg"
              class="ml-auto rounded-circle"
              width="150"
              height="150"
            />
          </div>
        </div>
        <!-- Tabs -->
        <form @submit.prevent="verifyOpertations">
          <!-- <ul class="nav nav-md-tabs nav-underline flex-md-row flex-wrap flex-column mt-4"> -->
          <ul class="nav nav-tabs flex-md-row flex-wrap flex-column mt-4">
            <li class="nav-item">
              <!-- <a class="nav-link active" href="#">Personal Info</a> -->
              <!-- :class="page == 'pinfo' ? ' active' : ''" -->
              <span
                class="nav-link"
                :class="{
                  active: page === 'pinfo' ? true : false,
                  'border-danger text-danger':
                    validator === 'pinfo' ? true : false,
                }"
                @click="styleChanger('pinfo')"
                >Personal Info</span
              >
            </li>
            <li class="nav-item">
              <!-- <a class="nav-link" href="#">Office Info</a> -->
              <span
                class="nav-link"
                :class="{
                  active: page === 'oinfo' ? true : false,
                  'border-danger text-danger':
                    validator === 'oinfo' ? true : false,
                }"
                @click="styleChanger('oinfo')"
                >Office Info</span
              >
            </li>
            <li class="nav-item">
              <!-- <a class="nav-link" href="#">Skill</a> -->
              <span
                class="nav-link"
                :class="page == 'skills' ? ' active' : ''"
                @click="styleChanger('skills')"
                v-if="addUser == false"
                >Skills</span
              >
            </li>
            <li class="nav-item">
              <!-- <a class="nav-link" href="#">KRA</a> -->
              <span
                class="nav-link"
                :class="page == 'kra' ? ' active' : ''"
                @click="styleChanger('kra')"
                v-if="addUser == false"
                >KRA</span
              >
            </li>
            <li class="nav-item">
              <!-- <a class="nav-link" href="#">KRA</a> -->
              <span
                class="nav-link"
                :class="page == 'goals' ? ' active' : ''"
                @click="styleChanger('goals')"
                v-if="addUser == false"
                >Goals</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link"
                :class="page == 'bank_details' ? ' active' : ''"
                @click="styleChanger('bank_details')"
                >Bank Details</span
              >
            </li>
            <li class="nav-item" v-if="!addUser">
              <span
                class="nav-link"
                :class="page == 'assets' ? ' active' : ''"
                @click="styleChanger('assets')"
                >Assets</span
              >
            </li>
            <li class="nav-item" v-if="!addUser">
              <span
                class="nav-link"
                :class="page == 'projects' ? ' active' : ''"
                @click="styleChanger('projects')"
                >Projects</span
              >
            </li>
            <!-- {{reqObj.user_type}} -->
            <li
              class="nav-item"
              v-if="[1, 2].includes(reqObj.user_type) && !addUser"
            >
              <span
                class="nav-link"
                :class="page == 'rewards' ? ' active' : ''"
                @click="styleChanger('rewards')"
                >Rewards</span
              >
            </li>

            <button
              class="btn btn-outline-primary ms-auto btn-sm"
              v-if="!isEditable && adminChecker() && !addUser"
              @click.prevent="makeEditable"
              style="width: 6rem"
            >
              Edit
            </button>
            <button
              class="btn btn-primary ms-auto btn-sm"
              ref="submitButton"
              type="submit"
              v-if="addUser"
            >
              Submit
            </button>
            <!-- <button
              class="btn btn-outline-secondary ml-auto"
              v-if="isEditable"
              @click.prevent="makeEditable"
              style="width: 6rem"
            >
              View
            </button> -->
            <!-- :class="!allowAction ? 'btn-outline-success' : 'btn-success'"
            :disabled="!allowAction" -->
            <div
              class="d-flex justify-content-end ms-auto align-items-center gap-2"
              v-if="isEditable && !addUser"
            >
              <button
                style="width: 6rem"
                type="submit"
                v-if="page != 'assets' && page != 'goals' && page != 'projects'"
                class="btn-success btn btn-sm"
                ref="submitButton"
              >
                Save
              </button>
              <button
                v-if="!addUser"
                @click.prevent="
                  isEditable = true;
                  makeEditable();
                "
                style="width: 6rem"
                class="btn btn-outline-secondary btn-sm"
              >
                Cancel
              </button>
            </div>
          </ul>
          <!-- <router-view></router-view> -->
          <div class="m-0 p-0">
            <!-- Personal info -->
            <div class="py-3" style="border-top: 0px" v-show="page == 'pinfo'">
              <h4 class="text-primary py-3" v-if="page == 'pinfo'">
                Personal Information
              </h4>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 1 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="first_name"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >First Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="reqObj.first_name"
                    :readonly="!isEditable"
                    required
                    placeholder="First Name"
                    @invalid="validationIndicator('pinfo')"
                    @input="validator = ''"
                    @keypress="isLetter($event)"
                  />
                </div>
                <!-- 2 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="middle_name" class="form-label"
                    >Middle Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :readonly="!isEditable"
                    placeholder="Middle Name"
                    v-model="reqObj.middle_name"
                    @keypress="isLetter($event)"
                  />
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 3 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="first_name"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Last Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    v-model="reqObj.last_name"
                    @keypress="isLetter($event)"
                    :readonly="!isEditable"
                    required
                    @invalid="validationIndicator('pinfo')"
                    @input="validator = ''"
                  />
                </div>
                <!-- 4 -->
                <div class="col-md col-12 mb-md-0 mb-3 form-check">
                  <label for="gender" class="form-label">Gender</label>
                  <div
                    class="form-check d-flex justify-content-start flex-md-row flex-column gap-md-5 py-1"
                  >
                    <div>
                      <input
                        type="radio"
                        class="form-check-input"
                        :disabled="!isEditable"
                        id="male"
                        value="M"
                        v-model="reqObj.gender"
                      />
                      <label for="Male" class="form-check-label">Male</label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        class="form-check-input"
                        :disabled="!isEditable"
                        id="female"
                        value="F"
                        v-model="reqObj.gender"
                      />
                      <label for="female" class="form-check-label"
                        >Female</label
                      >
                    </div>

                    <div>
                      <input
                        type="radio"
                        class="form-check-input"
                        :disabled="!isEditable"
                        id="other"
                        value="O"
                        v-model="reqObj.gender"
                      />
                      <label for="other" class="form-check-label">Other</label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        class="form-check-input"
                        :disabled="!isEditable"
                        id="unknown"
                        value="U"
                        v-model="reqObj.gender"
                      />
                      <label for="unknown" class="form-check-label"
                        >Unknown</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 6 -->
                <div class="col-md col-12 mb-md-0 mb-3" v-if="addUser">
                  <label
                    for="c_country"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Current Country</label
                  >
                  <select
                    id="cCountry"
                    v-model="reqObj.c_country"
                    class="form-control bg-white"
                    v-on:change="getCurrentStatesList()"
                    :disabled="!isEditable"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('pinfo')"
                  >
                    <option :value="null">Select Country</option>
                    <option
                      :key="index"
                      v-for="(country, index) in countries2"
                      :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <!-- 7 -->
                <div class="col-md col-12 mb-md-0 mb-3" v-if="addUser">
                  <label
                    for="password"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    v-model="reqObj.password"
                    :readonly="!isEditable"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('pinfo')"
                  />
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3" v-if="addUser">
                <!-- 8 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="dob" class="form-label">Date Of Birth</label>
                  <date-picker
                    type="date"
                    placeholder="yyyy-mm-dd"
                    v-model="reqObj.dob"
                    :disabled="!isEditable"
                    :clear-button="isEditable"
                    :calendar-button="true"
                    :clear-button-icon="'fa fa-times'"
                    :input-class="'border-start-0 bg-white'"
                    :calendar-button-icon="'fa fa-calendar'"
                    :bootstrap-styling="true"
                    :typeable="true"
                    :disabled-dates="disabledDates"
                    :format="formateDate"
                    @input="
                      errorObj.dobError = '';
                      validator = '';
                    "
                  ></date-picker>
                  <p style="color: #ff0000" v-if="errorObj.dobError">
                    {{ errorObj.dobError }}
                  </p>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3" v-else>
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="c_country"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Current Country</label
                  >
                  <select
                    id="cCountry"
                    v-model="reqObj.c_country"
                    class="form-control bg-white"
                    v-on:change="getCurrentStatesList()"
                    :disabled="!isEditable"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('pinfo')"
                  >
                    <option :value="null">Select Country</option>
                    <option
                      :key="index"
                      v-for="(country, index) in countries2"
                      :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="dob" class="form-label">Date Of Birth</label>
                  <date-picker
                    type="date"
                    placeholder="yyyy-mm-dd"
                    v-model="reqObj.dob"
                    :disabled="!isEditable"
                    :clear-button="isEditable"
                    :calendar-button="true"
                    :clear-button-icon="'fa fa-times'"
                    :input-class="'border-start-0 bg-white'"
                    :calendar-button-icon="'fa fa-calendar'"
                    :bootstrap-styling="true"
                    :typeable="true"
                    :disabled-dates="disabledDates"
                    :format="formateDate"
                    @input="
                      errorObj.dobError = '';
                      validator = '';
                    "
                  ></date-picker>
                  <p style="color: #ff0000" v-if="errorObj.dobError">
                    {{ errorObj.dobError }}
                  </p>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 9 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="blood_group" class="form-label"
                    >Blood Group</label
                  >
                  <select
                    v-model="reqObj.blood_group"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                  >
                    <option :value="null">Select Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="B+">B+</option>
                    <option value="AB+">AB+</option>
                    <option value="O+">O+</option>
                    <option value="A-">A-</option>
                    <option value="B-">B-</option>
                    <option value="AB-">AB-</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
                <!-- 10 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="emergency_contact_number" class="form-label"
                    >Emergency Contact Number</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Emergency Contact Number"
                    :readonly="!isEditable"
                    maxlength="10"
                    v-model="reqObj.emergency_contact_number"
                    @keypress="isNumber($event)"
                  />
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 11 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="emergency_contact_name" class="form-label"
                    >Emergency Contact Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="reqObj.emergency_contact_name"
                    :readonly="!isEditable"
                    placeholder="Emergency Contact Name"
                    @invalid="validationIndicator('pinfo')"
                    @input="validator = ''"
                    @keypress="isLetter($event)"
                  />
                </div>
                <!-- 12 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="emergency_contact_relation" class="form-label"
                    >Emergency Contact Relation</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="reqObj.emergency_contact_relation"
                    :readonly="!isEditable"
                    placeholder="Emergency Contact Relation"
                    @invalid="validationIndicator('pinfo')"
                    @input="validator = ''"
                    @keypress="isLetter($event)"
                  />
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 13 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="medical_info" class="form-label"
                    >Medical Information</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :readonly="!isEditable"
                    placeholder="Medical Information"
                    v-model="reqObj.medical_info"
                  />
                </div>
                <!-- 14 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="pan" class="form-label">Pan Card Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="example - DOAPM1234M"
                    :readonly="!isEditable"
                    maxlength="10"
                    v-model="reqObj.pan"
                    @input="
                      errorObj.panError = '';
                      validator = '';
                    "
                  />
                  <p style="color: #ff0000">{{ errorObj.panError }}</p>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 15 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="p_mobile" class="form-label"
                    >Personal Mobile
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Personal Mobile"
                    :readonly="!isEditable"
                    maxlength="10"
                    v-model="reqObj.p_mobile"
                    @keypress="isNumber($event)"
                  />
                  <p style="color: #ff0000">{{ errorObj.p_mobileError }}</p>
                </div>
                <!-- 16 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="p_email" class="form-label">Personal Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                    :readonly="!isEditable"
                    v-model="reqObj.p_email"
                    @input="
                      errorObj.p_emailError = '';
                      validator = '';
                    "
                    @invalid="validationIndicator('pinfo')"
                  />
                  <p style="color: #ff0000">{{ errorObj.p_emailError }}</p>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 17 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="c_add1" class="form-label">Current Address</label>
                  <textarea
                    id="cAddress"
                    rows="2"
                    wrap="soft"
                    class="form-control"
                    placeholder="Address Line"
                    :readonly="!isEditable"
                    v-model="reqObj.c_add1"
                    @keydown.enter.prevent
                    @keydown.space="preventLeadingSpace($event)"
                  ></textarea>
                </div>
                <!-- 18 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="c_state" class="form-label"
                    >Current Address State</label
                  >
                  <select
                    id="cState"
                    v-model="reqObj.c_state"
                    class="form-control bg-white"
                    v-on:change="getCurrentCitiesList()"
                    :disabled="!isEditable"
                  >
                    <option value="0">Select State</option>
                    <option
                      :key="index"
                      v-for="(state, index) in states2"
                      :value="state.id"
                    >
                      {{ state.statename }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 19 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="c_city" class="form-label"
                    >Current Address City
                  </label>
                  <select
                    id="cCity"
                    v-model="reqObj.c_city"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                  >
                    <option value="0">Select City</option>
                    <option
                      :key="index"
                      v-for="(city, index) in cities2"
                      :value="city.id"
                    >
                      {{ city.cityname }}
                    </option>
                  </select>
                </div>
                <!-- 20 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="c_zip" class="form-label"
                    >Current Address Zip</label
                  >
                  <input
                    type="text"
                    id="cZipCode"
                    class="form-control"
                    :readonly="!isEditable"
                    placeholder="Zip code"
                    maxlength="6"
                    v-model="reqObj.c_zip"
                    @input="errorObj.c_zipError = ''"
                  />
                  <p style="color: #ff0000">{{ errorObj.c_zipError }}</p>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 21 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="p_add1" class="form-label"
                    >Permanent Address</label
                  >
                  <textarea
                    id="pAddress"
                    rows="2"
                    wrap="soft"
                    class="form-control"
                    :readonly="!isEditable"
                    placeholder="Address"
                    v-model="reqObj.p_add1"
                    @keydown.space="preventLeadingSpace($event)"
                    @keydown.enter.prevent
                  ></textarea>
                </div>
                <!-- 22 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="p_country" class="form-label"
                    >Permanent Address Country</label
                  >
                  <select
                    id="pCountry"
                    v-model="reqObj.p_country"
                    class="form-control bg-white"
                    v-on:change="getPermanentStatesList()"
                    :disabled="!isEditable"
                  >
                    <option value="0">Select Country</option>
                    <option
                      :key="index"
                      v-for="(country, index) in countries"
                      :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row row-cols-md-2 gy-3 mb-3">
                <!-- 23 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="p_state" class="form-label"
                    >Permanent Address State</label
                  >
                  <select
                    id="pState"
                    v-model="reqObj.p_state"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                    v-on:change="getPermanentCitiesList()"
                  >
                    <option value="0">Select State</option>
                    <option
                      :key="index"
                      v-for="(state, index) in states"
                      :value="state.id"
                    >
                      {{ state.statename }}
                    </option>
                  </select>
                </div>
                <!-- 24 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="p_city" class="form-label"
                    >Permanent Address City
                  </label>
                  <select
                    id="pCity"
                    v-model="reqObj.p_city"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                  >
                    <option value="0">Select City</option>
                    <option
                      :key="index"
                      v-for="(city, index) in cities"
                      :value="city.id"
                    >
                      {{ city.cityname }}
                    </option>
                  </select>
                </div>
                <!-- :class="[isEditable ? 'mandatory' : '', 'form-label']" -->
                <!-- :readonly="!isEditable" -->
              </div>
            </div>
            <!-- Office info -->
            <div class="py-3" v-show="page == 'oinfo'">
              <!-- <h4 class="py-3 px-5  mb-5 text-left border-bottom">Personal Information</h4> -->
              <h4 class="text-primary py-3" v-if="page == 'oinfo'">
                Office Information
              </h4>

              <div class="row row-cols-md-2 mb-md-3 mb-0">
                <!-- 1 start -->
                <div
                  class="col-md col-12 mb-md-0 mb-3"
                  v-if="currentUser.user_role === 1"
                >
                  <label for="doe" class="form-label">Organization</label>
                  <select
                    id="organization"
                    v-model="reqObj.organization"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                  >
                    <option :value="null">Select Organization</option>
                    <option
                      :key="index"
                      v-for="(o, index) in organizations"
                      :value="o.id"
                    >
                      {{ o.name }}
                    </option>
                  </select>
                </div>
                <!-- 2 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="emp_code" class="form-label">Employee Code</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Employee Code"
                    maxlength="10"
                    :readonly="!isEditable"
                    v-model="reqObj.emp_code"
                  />
                </div>
              </div>

              <div class="row row-cols-md-2 mb-md-3 mb-0">
                <!-- 3 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Username</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Username"
                    v-model="reqObj.username"
                    :readonly="!isEditable"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('oinfo')"
                  />
                </div>
                <!-- 4 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="doj"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Date Of Joining</label
                  >
                  <!-- :input-class="[!isEditable ? 'bg-white' : '','border-start-0']" -->
                  <date-picker
                    type="date"
                    placeholder="yyyy-mm-dd"
                    v-model="reqObj.doj"
                    :clear-button="isEditable"
                    :calendar-button="true"
                    :clear-button-icon="'fa fa-times'"
                    :input-class="
                      !isEditable ? 'bg-white border-start-0' : 'border-start-0'
                    "
                    :calendar-button-icon="'fa fa-calendar'"
                    :bootstrap-styling="true"
                    :disabled="!isEditable"
                    :typeable="true"
                    :disabled-dates="disabledDates"
                    :format="formateDate"
                    @invalid="validationIndicator('pinfo')"
                    @input="validator = ''"
                  ></date-picker>
                </div>
              </div>

              <div class="row row-cols-md-2 mb-md-3 mb-0">
                <!-- 5 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="o_email" class="form-label">Office Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="example@gmail.com"
                    :readonly="!isEditable"
                    v-model="reqObj.o_email"
                    @invalid="validationIndicator('oinfo')"
                    @input="
                      errorObj.emailError = '';
                      validator = '';
                      errorObj.emailError = '';
                    "
                  />
                  <p style="color: #ff0000" v-if="errorObj.emailError">
                    {{ errorObj.emailError }}
                  </p>
                </div>
                <!-- 6 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="user-role" class="form-label"
                    >User Designation</label
                  >
                  <select
                    v-model="reqObj.designation_id"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                  >
                    <option :value="null">Select user designation</option>
                    <option
                      :key="index"
                      v-for="(item, index) in designations"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row row-cols-2 mb-md-3 mb-0">
                <!-- 7 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="user-role"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >User Role</label
                  >
                  <select
                    v-model="reqObj.user_role"
                    class="form-control bg-white"
                    :disabled="user_role == 1 && isEditable ? false : true"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('oinfo')"
                  >
                    <option :value="null">Select user role</option>
                    <option
                      :key="index"
                      v-for="(item, index) in roles"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <!-- 8 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="user-type"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >User Type</label
                  >
                  <select
                    v-model="reqObj.user_type"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('oinfo')"
                  >
                    <option :value="null">Select user type</option>
                    <option
                      :key="index"
                      v-for="(item, index) in user_types"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row row-cols-2 mb-md-3 mb-03">
                <!-- 9 -->
                <div class="col-md col-12 row mb-md-0 mb-3">
                  <div class="col-md-10 col-12 mb-md-0 mb-3">
                    <label
                      for="User manager id"
                      :class="[isEditable ? 'mandatory' : '', 'form-label']"
                      >Report To</label
                    >
                    <select
                      v-if="isEditable || currentUser.user_role == 1"
                      :disabled="!isEditable"
                      v-model="reqObj.report_to"
                      class="form-control bg-white"
                      required
                      @invalid="validationIndicator('oinfo')"
                      @input="validator = ''"
                    >
                      <option :value="null">Select manager name</option>
                      <option
                        :key="index"
                        v-for="(item, index) in managers"
                        :value="item.id"
                      >
                        {{ item.first_name }} {{ item.last_name }}
                      </option>
                    </select>
                    <input
                      v-else
                      class="form-control"
                      readonly
                      :value="reqObj.report_to_name"
                    />
                  </div>
                  <div class="col-md-2 col-12">
                    <label for="doe" class="mb-2">Reporting</label>
                    <input
                      class="form-check-input ms-md-0 ms-2 d-md-block"
                      type="checkbox"
                      :disabled="!isEditable"
                      v-model="reqObj.reporting"
                    />
                  </div>
                </div>
                <!-- 10 -->
                <div class="col-md col-12 mb-md-0 mb-3 row row-cols-md-3">
                  <div
                    class="col-md col-12 d-flex flex-column justify-content-center mb-md-0 mb-3"
                  >
                    <label
                      for="login slot"
                      :class="[isEditable ? 'mandatory' : '', 'form-label']"
                      >Login Slot</label
                    >
                    <flat-pickr
                      type="time"
                      class="form-control bg-white"
                      placeholder="Select login slot"
                      :config="config"
                      v-model="reqObj.login_slot"
                      :disabled="!isEditable"
                      @on-change="loginSlotInput"
                      :modelValue="reqObj.login_slot"
                    ></flat-pickr>
                    <small
                      style="color: #ff0000"
                      v-if="errorObj.login_slot_error"
                    >
                      {{ errorObj.login_slot_error }}
                    </small>
                  </div>
                  <div
                    class="col-md col-12 d-flex flex-column justify-content-center mb-md-0 mb-3"
                  >
                    <label
                      for="user-role"
                      :class="[isEditable ? 'mandatory' : '', 'form-label']"
                      >Per day hours</label
                    >
                    <div>
                      <vue-time-picker
                        format="HH:mm"
                        :minute-interval="15"
                        :disabled="!isEditable"
                        input-class="form-control text-dark bg-white"
                        v-model="reqObj.per_day_minutes"
                        @change="
                          errorObj.per_day_minutes_error = '';
                          validator = '';
                        "
                      ></vue-time-picker>
                      <small
                        style="color: #ff0000"
                        v-if="errorObj.per_day_minutes_error"
                      >
                        {{ errorObj.per_day_minutes_error }}
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md col-12 d-flex flex-column justify-content-center"
                  >
                    <label
                      for="user-role"
                      :class="[isEditable ? 'mandatory' : '', 'form-label']"
                      >Break Hours</label
                    >
                    <div>
                      <vue-time-picker
                        format="HH:mm"
                        :minute-interval="15"
                        :disabled="!isEditable"
                        input-class="form-control text-dark bg-white"
                        v-model="reqObj.break_minutes"
                        @change="
                          errorObj.break_minutes_error = '';
                          validator = '';
                        "
                      ></vue-time-picker>
                      <small
                        style="color: #ff0000"
                        v-if="errorObj.break_minutes_error"
                      >
                        {{ errorObj.break_minutes_error }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="  row row-cols-md-2 mb-md-3 mb-0">
              </div> -->

              <div class="row row-cols-md-2 mb-md-3 mb-0">
                <!-- 12 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label
                    for="user-role"
                    :class="[isEditable ? 'mandatory' : '', 'form-label']"
                    >Weekend Working Strategy</label
                  >
                  <select
                    v-model="reqObj.weekend_strategy"
                    class="form-control bg-white"
                    :disabled="!isEditable"
                    required
                    @input="validator = ''"
                    @invalid="validationIndicator('pinfo')"
                  >
                    <option :value="null" selected>
                      --Select Weekend Working Strategy --
                    </option>
                    <option value="1">All saturday off</option>
                    <option value="2">Alternate saturday off</option>
                    <option value="3">All saturday on</option>
                  </select>
                </div>
                <!-- 13 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="uan_no" class="form-label"
                    >Universal Account Number :</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter universal account number"
                    v-model="reqObj.uan_no"
                    :readonly="!isEditable"
                  />
                </div>
              </div>

              <div class="row row-cols-md-2 mb-md-3 mb-0">
                <!-- 14 -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="emp_code" class="form-label">ESIC Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ESIC Number"
                    maxlength="17"
                    :readonly="!isEditable"
                    v-model="reqObj.esic_no"
                  />
                </div>
                <!-- 15 end -->
                <div class="col-md col-12 mb-md-0 mb-3">
                  <label for="doe" class="form-label">Date Of Exit</label>
                  <date-picker
                    type="date"
                    placeholder="yyyy-mm-dd"
                    v-model="reqObj.doe"
                    :clear-button="isEditable"
                    :disabled="!isEditable"
                    :calendar-button="true"
                    :clear-button-icon="'fa fa-times'"
                    :input-class="
                      !isEditable ? 'bg-white border-start-0' : 'border-start-0'
                    "
                    :calendar-button-icon="'fa fa-calendar'"
                    :bootstrap-styling="true"
                    :typeable="true"
                    :format="formateDate"
                  ></date-picker>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- Skills -->
        <div class="py-3" v-if="page == 'skills'">
          <h4 class="text-start text-primary py-3" v-if="page == 'skills'">
            Skills
          </h4>

          <div class="mx-0 my-3 p-0 container-fluid">
            <div class="row justify-content-start align-items-center">
              <div
                class="col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                v-for="(skill, index) in skills"
                :key="index"
              >
                <div
                  class="card"
                  :class="selected_skills.includes(skill.id) ? 'bg-light' : ''"
                >
                  <div
                    class="card-body d-flex align-items-center justify-content-around"
                  >
                    <img
                      :alt="skill.name"
                      :src="skill.image"
                      class="m-0 p-0 img-fluid"
                    />
                    <h4 class="mx-2 my-0 p-0" v-text="skill.name"></h4>
                    <input
                      type="checkbox"
                      style="scale: 1.3"
                      :disabled="!isEditable"
                      v-model="selected_skills"
                      :value="skill.id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- KRA -->
        <div class="py-3" v-if="page == 'kra'">
          <h4 class="text-primary py-3" v-if="page == 'kra'">KRA</h4>
          <div class="mx-0 my-3 p-0">
            <div class="row">
              <div
                class="col-sm-6 col-12 mb-4"
                v-for="(kra, index) in kra_list"
                :key="index"
              >
                <div
                  class="card border shadow-sm mb-3"
                  style="height: 14rem"
                  :class="selected_kra.includes(kra.id) ? 'bg-light' : ''"
                >
                  <div
                    class="card-header d-flex justify-content-between align-items-center px-3 py-2"
                  >
                    <h5 class="text-primary" v-text="kra.name"></h5>
                    <input
                      type="checkbox"
                      style="scale: 1.3"
                      :value="kra.id"
                      :disabled="!isEditable"
                      v-model="selected_kra"
                    />
                  </div>
                  <div
                    class="card-body text-left"
                    v-text="kra.description"
                    style="overflow-y: auto"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- GOALS -->
        <div class="py-3" v-if="page == 'goals'">
          <h4 class="text-primary py-3" v-if="page == 'goals'">Goals</h4>
          <div class="row mx-0 my-3 p-0 gx-0" v-if="goals.length !== 0">
            <div class="col-md-6" v-for="(item, index) in goals" :key="index">
              <div
                class="card border shadow-sm mb-2 ms-2"
                style="height: 15rem"
              >
                <div
                  class="card-header d-flex justify-content-start align-items-center"
                >
                  <h5 class="text-primary m-0" v-text="trimer(item.title)"></h5>
                </div>
                <div
                  class="card-body text-left text-wrap"
                  v-text="item.description"
                  style="overflow-y: auto"
                ></div>
                <div
                  class="card-footer d-flex justify-content-between align-items-center px-3 py-2"
                >
                  <small
                    >Start date: {{ item.start_date }} &emsp; Due date:
                    {{ item.end_date }}</small
                  >
                  <h3
                    v-text="item.progress + '%'"
                    class="badge badge-primary"
                  ></h3>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid p-5 text-center text-muted" v-else>
            <h3>No goals found</h3>
          </div>
        </div>

        <!-- Bank Details -->
        <div class="py-3" v-if="page == 'bank_details'">
          <h4 class="text-left text-primary py-3" v-if="page == 'bank_details'">
            Bank Details
          </h4>

          <div class="row row-cols-md-2 mb-md-3 mb-0">
            <div class="col-md col-12">
              <label for="emp_code" class="form-label"> Bank Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Bank Name"
                maxlength="60"
                :readonly="!isEditable"
                v-model="reqObj.bank_name"
                oninvalid="this.setCustomValidity('Please enter bank name')"
                oninput="this.setCustomValidity('')"
              />
            </div>
            <div class="col-md col-12">
              <label for="emp_code" class="form-label"> Account Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="Account Number"
                maxlength="17"
                minlength="9"
                :readonly="!isEditable"
                v-model="reqObj.bank_account_no"
                oninvalid="this.setCustomValidity('Please enter bank account number')"
                oninput="this.setCustomValidity('')"
              />
            </div>
          </div>

          <div class="row row-cols-md-2 mb-md-3 mb-0">
            <div class="col-md col-12">
              <label for="ifsc_no" class="form-label"> IFSC No</label>
              <input
                type="text"
                class="form-control"
                placeholder="IFSC No"
                maxlength="17"
                :readonly="!isEditable"
                v-model="reqObj.ifsc_no"
                oninvalid="this.setCustomValidity('Please enter IFSC No')"
                oninput="this.setCustomValidity('')"
              />
            </div>
          </div>
        </div>

        <!-- Assets -->
        <div class="py-3" v-if="page == 'assets'">
          <div class="d-flex justify-content-between align-items-center py-3">
            <h4 class="m-0 text-primary">Assets</h4>
            <button
              class="btn btn-success py-2"
              @click="assetModalHandler(true)"
              v-if="
                currentUser.user_role == 1 &&
                permissions.includes('assets.allocate')
              "
            >
              Allocate
            </button>
          </div>
          <div class="mt-2" style="overflow-x: auto">
            <h6
              class="text-end mb-2"
              v-if="total_price && currentUser.user_role == 1"
            >
              Total Value : &#8377;{{ total_price }}
            </h6>
            <table
              class="table table-bordered text-center valign-center"
              v-if="user_asset_list"
            >
              <thead class="table-light">
                <tr>
                  <th style="width: 1.5rem">Status</th>
                  <th style="width: 20rem">Asset Name</th>
                  <th style="width: 20rem">Serial No</th>
                  <th style="width: 20rem" v-if="currentUser.user_role == 1">
                    Price
                  </th>
                  <th style="width: 20rem">Start Date</th>
                  <th style="width: 20rem">End Date</th>
                  <th style="width: 20rem">Notes</th>
                  <th style="width: 10rem" v-if="currentUser.user_role == 1">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody v-if="user_asset_list.length > 0">
                <!-- :class="item.show_deallocate_button ? 'text-success' : 'text-danger'" -->
                <tr v-for="(item, index) in user_asset_list" :key="index">
                  <td class="">
                    <div
                      class="rounded-circle mx-auto mt-2"
                      :class="
                        item.end_date == null ? 'bg-success' : 'bg-danger'
                      "
                      style="height: 15px; width: 15px"
                    ></div>
                  </td>
                  <td class="fw-normal" v-text="item.name"></td>
                  <td class="fw-normal" v-if="currentUser.user_role == 1">
                    <a
                      class="link link-underline link-underline-opacity-0 link-underline-opacity-100-hover cursor-pointer"
                      @click.prevent="assetRedirectionHandler(item.id)"
                    >
                      {{ item.serial_no }}
                    </a>
                  </td>
                  <td class="fw-normal" v-else v-text="item.serial_no"></td>
                  <td class="fw-normal" v-if="currentUser.user_role == 1">
                    &#8377;{{ item.price.toLocaleString() }}
                  </td>
                  <td class="fw-normal" v-text="item.start_date"></td>
                  <td
                    class="fw-normal"
                    v-text="item.end_date ? item.end_date : '-'"
                  ></td>
                  <td
                    class="fw-normal"
                    v-text="item.notes ? item.notes : '-'"
                  ></td>
                  <td v-if="currentUser.user_role == 1">
                    <div class="">
                      <button
                        class="btn btn-danger d-block mx-auto"
                        v-if="
                          item.show_deallocate_button &&
                          permissions.includes('assets.allocate')
                        "
                        @click="assetModalHandler(false, item)"
                      >
                        Deallocate
                      </button>
                      <span class="font-italic fw-normal text-mute" v-else
                        >deallocated</span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    :colspan="currentUser.user_role == 1 ? 8 : 6"
                    class="text-center p-3"
                  >
                    No data available
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-5 text-center" v-else>
              <span
                class="spinner-border text-primary text-center mx-auto"
                style="height: 4rem; width: 4rem"
              ></span>
            </div>
          </div>
        </div>
        <!-- Projects -->
        <div class="py-3" v-if="page == 'projects'">
          <h4 class="m-0 text-primary py-3">Projects</h4>
          <div class="mt-3" style="overflow-x: auto">
            <table
              class="table table-bordered text-center valign-center"
              v-if="projects"
            >
              <!-- v-if="!projects" -->
              <thead class="table-light">
                <tr>
                  <th style="width: 20rem">Project Name</th>
                  <th style="width: 20rem">Manager</th>
                  <th style="width: 20rem">Description</th>
                  <th style="width: 20rem">Type</th>
                  <th style="width: 20rem">Status</th>
                  <!-- <th style="width: 20rem">Notes</th> -->
                </tr>
              </thead>
              <tbody v-if="projects.length > 0">
                <!-- :class="item.show_deallocate_button ? 'text-success' : 'text-danger'" -->
                <!-- <tr> -->
                <tr v-for="(item, index) in projects" :key="index">
                  <td class="fw-normal" v-text="item.project_name"></td>
                  <td class="fw-normal" v-text="item.first_name"></td>
                  <td class="fw-normal" v-text="item.description"></td>
                  <td
                    class="fw-normal"
                    v-text="item.project_type == 1 ? 'Fixed' : 'Hourly'"
                  ></td>
                  <td class="fw-normal" v-text="item.project_status"></td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td :colspan="5" class="text-center p-3">
                    No data available
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-5 text-center" v-else>
              <span
                class="spinner-border text-primary text-center mx-auto"
                style="height: 4rem; width: 4rem"
              ></span>
            </div>
          </div>
        </div>
        <!-- Rewards -->
        <div class="py-3 px-md-2 px-0" v-if="page == 'rewards'">
          <RewardComp
            :page="'your_rwd'"
            :user_id="parseInt($route.params.id)"
            :setPage="false"
          />
        </div>
      </div>
    </div>
    <!-- Asset Modal -->
    <div
      class="modal fade"
      id="assetModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="mb-0" id="exampleModalLabel">
              {{ assignAssetObj.title }}
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body px-4">
            <div class="row no-gutters mb-3" v-if="allocate_asset">
              <label class="form-label mandatory" for="asset"
                >Select Asset</label
              >
              <multiselect
                id="asset"
                v-model="assignAssetObj.asset_ids"
                :options="assign_asset_list"
                label="serial_no"
                track-by="id"
                placeholder="Assign available assets to user"
                @select="startDateHandler"
                @remove="assignAssetObjectReseter"
              ></multiselect>
              <small
                class="text-danger"
                v-text="assignAssetObj.cat_error"
              ></small>
            </div>
            <div class="row no-gutters mb-3" v-if="assignAssetObj.asset_ids">
              <label class="form-label mandatory" for="start_date"
                >Start Date</label
              >
              <flat-pickr
                :disabled="!allocate_asset"
                v-model="assignAssetObj.start_date"
                @on-change="inputDate($event, 'start_date')"
                modelValue=""
                id="start_date"
                ref="startDatePickr"
                class="form-control"
                :config="start_date_config"
                :class="allocate_asset ? 'bg-white' : 'bg-light'"
                placeholder="Select the start date"
              ></flat-pickr>
              <small
                class="text-danger"
                v-text="assignAssetObj.start_date_error"
              ></small>
            </div>
            <div
              class="row no-gutters mb-3"
              v-if="assignAssetObj.asset_ids && (!allocate_asset || old_asset)"
            >
              <label class="form-label mandatory" for="end_date"
                >End Date</label
              >
              <flat-pickr
                v-model="assignAssetObj.end_date"
                ref="endDatePickr"
                @on-change="inputDate($event, 'end_date')"
                modelValue=""
                id="end_date"
                :config="end_date_config"
                class="form-control bg-white"
                placeholder="Select the end date"
              ></flat-pickr>
              <small
                class="text-danger"
                v-text="assignAssetObj.end_date_error"
              ></small>
            </div>
            <div class="row no-gutters mb-3" v-if="assignAssetObj.asset_ids">
              <label class="form-label" for="note">Note</label>
              <textarea
                class="form-control"
                placeholder="Enter the note"
                id="date"
                cols="30"
                rows="2"
                v-model="assignAssetObj.notes"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="submitUserAsset"
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      \
    </div>
    <!-- Image profile modal -->
    <div
      class="modal fade"
      id="imageModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-white">
            <h3 class="m-0" id="exampleModalLabel">Profile Image</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body d-flex align-items-center justify-content-center p-5"
          >
            <img
              v-if="reqObj.image_url"
              :src="reqObj.image_url"
              class="rounded-circle"
              width="200"
              height="200"
            />
            <img
              v-else
              src="../img/user.svg"
              class="rounded-circle"
              width="200"
              height="200"
            />
          </div>
          <div
            class="modal-footer bg-white d-flex border justify-content-center align-items-center"
          >
            <label class="btn btn-primary" for="profile">Edit</label>
            <input
              type="file"
              class="d-none"
              id="profile"
              @change="onFileUpload"
            />
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="RemovePhoto"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import user from "@/ajax/user";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import flatPickr from "vue-flatpickr-component";
import VueTimePicker from "vue2-timepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import RewardComp from "../../components/RewardComp.vue";
import ReadMore from "vue-read-more";
moment.locale("en");
Vue.component("RewardComp", RewardComp);
Vue.use(ReadMore);

let selected_kra_copy = [];
let selected_skills_copy = [];
let userObj_copy = null;

export default {
  data() {
    return {
      mixins: [myMixin],
      page: "pinfo",
      FILE: null,
      isEditable: false,
      components: {
        Datepicker,
        flatPickr,
        VueTimePicker,
        Multiselect,
        RewardComp,
      },
      reqObj: {
        first_name: null,
        middle_name: null,
        last_name: null,
        pan: null,
        dob: null,
        doj: new Date().toISOString().slice(0, 10),
        doe: null,
        emp_code: null,
        o_email: null,
        username: null,
        password: null,
        blood_group: null,
        medical_info: null,
        p_mobile: null,
        p_email: null,
        p_add1: null,
        p_city: null,
        p_state: null,
        p_country: null,
        p_zip: null,
        c_add1: null,
        c_city: null,
        c_state: null,
        c_country: null,
        c_zip: null,
        report_to: null,
        login_slot: null,
        user_role: null,
        designation_id: null,
        user_type: null,
        per_day_minutes: { HH: "09", mm: "00" },
        break_minutes: { HH: "00", mm: "00" },
        weekend_strategy: 1,
        uan_no: null,
        image: null,
        image_url: null,
        organization: null,
        reporting: null,
        bank_name: null,
        bank_account_no: null,
        esic_no: null,
        ifsc_no: null,
        gender: null,
        emergency_contact_number: null,
        emergency_contact_name: null,
        emergency_contact_relation: null,
        report_to_name: null,
      },
      image_type: "profile",
      img_save_path: "",
      errorObj: {
        panError: "",
        dobError: "",
        emailError: "",
        passwordError: "",
        p_mobileError: "",
        p_emailError: "",
        p_zipError: "",
        c_zipError: "",
        per_day_minutes_error: "",
        login_slot_error: "",
        break_minutes_error: "",
      },
      roles: [],
      designations: [],
      user_types: [],
      countries: [],
      countries2: [],
      states: [],
      states2: [],
      cities: [],
      cities2: [],
      disabledDates: {
        from: new Date(Date.now()),
      },
      editid: 0,
      managers: [],
      organizations: [],
      skills: [],
      selected_skills: [],
      selected_skills_copy: [],

      kra_list: [],
      users: [],
      names: [],
      selected_kra: [],
      selected_kra_copy: [],
      goals: [],
      alias: [],
      clients: [],
      assign_asset_list: [],
      user_asset_list: null,

      permissions: window.localStorage.getItem("permissions"),
      user_role: localStorage.getItem("user_role"),
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        enableInput: false,
      },
      role: localStorage.getItem("user_role"),
      validator: "",
      allowAction: false,
      imageClicked: false,
      addUser: false,
      allocate_asset: true,
      old_asset: false,
      assignAssetObj: {
        title: "Allocate Asset",
        asset_ids: null,
        notes: null,
        start_date: null,
        start_date_error: "",
        end_date_error: "",
        cat_error: "",
        // end_date:null
      },
      asset_start_date: null,
      start_date_config: {
        dateFormat: "Y-m-d",
      },
      end_date_config: {
        dateFormat: "Y-m-d",
      },
      projects: null,
      total_price: null,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  methods: {
    ...mapActions("userStore", [
      "setCurrentUser",
      "logoutUser",
      "setAccessToken",
    ]),
    ...mapActions("common", ["setCurrentPage"]),
    assetRedirectionHandler(id) {
      let redirect_link = this.$router.resolve({ name: "Assets" });
      window.open(redirect_link.href, "_blank");
      let assetsList = JSON.parse(localStorage.getItem("assets-list"));
      assetsList.push({ asset_id: id });
      localStorage.setItem("assets-list", JSON.stringify(assetsList));
    },
    startDateHandler(event) {
      this.asset_start_date = event.start_date;
      this.$nextTick(() => {
        this.assignAssetObj.start_date = null;
        this.start_date_config.enable = [
          {
            from: "2000-01-01",
            to: moment().format("YYYY-MM-DD"),
          },
        ];
        this.$refs.startDatePickr.fp.clear();
      });
      this.assignAssetObj.notes = null;
      this.old_asset = false;
      delete this.assignAssetObj["end_date"];
      delete this.end_date_config.enable;
      this.$forceUpdate();
    },
    assignAssetObjectReseter() {
      this.old_asset = false;
      this.asset_start_date = null;
      this.assignAssetObj.start_date = null;
      this.assignAssetObj.notes = null;
      Object.keys(this.assignAssetObj).includes("end_date")
        ? delete this.assignAssetObj["end_date"]
        : "";
    },
    getUserAssets(id) {
      this.total_price = null;
      user.get_user_assets(id).then((res) => {
        this.user_asset_list = null;
        this.user_asset_list = res.data.data.data;
        this.total_price = Number(res.data.data.total_price).toLocaleString();
      });
    },
    resetModalHandler() {
      this.assignAssetObj.asset_ids = null;
      this.assignAssetObj.notes = null;
      this.assignAssetObj.start_date = null;
      if (Object.keys(this.assignAssetObj).includes("end_date")) {
        this.assignAssetObj.end_date = null;
        delete this.end_date_config.enable;
      }
      this.assignAssetObj.start_date_error = "";
      this.assignAssetObj.end_date_error = "";
      this.assignAssetObj.title = "";
      this.asset_start_date = null;
    },
    assetModalHandler(allocate, item = null) {
      this.allocate_asset = allocate;
      if (allocate) {
        this.assignAssetObj.title = "Allocate Asset";
      } else {
        this.assignAssetObj.start_date = item.start_date;
        this.assignAssetObj.end_date = item.end_date;
        this.end_date_config.enable = [
          {
            from: this.assignAssetObj.start_date,
            to: moment().format("YYYY-MM-DD"),
          },
        ];
        this.assignAssetObj.title = "Deallocate Asset";
        this.assignAssetObj.asset_ids = item.id;
        this.assignAssetObj.map_id = item.map_id;
        this.assignAssetObj.notes = item.notes;
      }
      $("#assetModal").modal("show");
    },
    assignAssetValidator() {
      let check = true;
      if (this.assignAssetObj.asset_ids == null) {
        this.assignAssetObj.cat_error = "Please select the asset";
        return (check = false);
      } else {
        this.assignAssetObj.cat_error = "";
        check = true;
      }
      if (this.assignAssetObj.start_date == null) {
        this.assignAssetObj.start_date_error = "Please select the start date";
        return (check = false);
      } else {
        this.assignAssetObj.start_date_error = "";
        check = true;
      }
      if (Object.keys(this.assignAssetObj).includes("end_date")) {
        if (this.assignAssetObj.end_date == null) {
          this.assignAssetObj.end_date_error = "Please select the end date";
          return (check = false);
        } else {
          this.assignAssetObj.end_date_error = "";
          check = true;
        }
      }
      return check;
    },
    submitUserAsset() {
      if (this.assignAssetValidator()) {
        let tempObj = Object.assign({}, this.assignAssetObj);
        tempObj.start_date = moment(tempObj.start_date).format("YYYY-MM-DD");
        tempObj.user_id = Number(this.$route.params.id);
        delete tempObj.start_date_error;
        delete tempObj.end_date_error;
        delete tempObj.cat_error;
        if (this.allocate_asset) {
          tempObj.asset_ids = tempObj.asset_ids.id;
          user
            .assign_asset_to_user(tempObj)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.getUserAssets(this.$route.params.id);
              this.get_assign_asset();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        } else {
          user
            .deallocated_asset_of_user(tempObj)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.getUserAssets(this.$route.params.id);
              this.get_assign_asset();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
        $("#assetModal").modal("hide");
      }
    },
    get_assign_asset() {
      user.get_assign_asset().then((result) => {
        this.assign_asset_list = result.data.data;
      });
    },
    inputDate(event, key) {
      this.assignAssetObj[key] = moment(event[0]).format("YYYY-MM-DD");
      if (
        this.asset_start_date != null &&
        moment(this.assignAssetObj.start_date).isBefore(
          this.asset_start_date
        ) &&
        key == "start_date"
      ) {
        this.old_asset = true;
        this.assignAssetObj.end_date = null;
        this.end_date_config.enable = [
          {
            from: this.assignAssetObj.start_date,
            to: moment().format("YYYY-MM-DD"),
          },
        ];
      }
      if (
        this.asset_start_date != null &&
        !moment(this.assignAssetObj.start_date).isBefore(
          this.asset_start_date
        ) &&
        key == "start_date"
      ) {
        this.old_asset = false;
        delete this.assignAssetObj["end_date"];
      }
    },
    getUserProjectList(id) {
      user.get_user_projects(id).then((result) => {
        this.projects = result.data.data;
        // console.log(this.projects);
      });
    },
    RemovePhoto() {
      $("#imageModal").modal("hide");
      this.reqObj.image = null;
      this.imageClicked = true;
      this.verifyOpertations();
    },
    onFileUpload(event) {
      // console.log("---",this.reqObj.image)
      if (event.target.value !== "") {
        this.getPreAssighedUrl().then(() => {
          if (event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
            if (event.target.files[0].size < 1000000) {
              // console.log("done")
              this.FILE = event.target.files[0];
              const formdata = new FormData();
              formdata.append("file", this.FILE);
              axios
                .put(this.img_save_path, this.FILE, {
                  headers: {
                    "Content-Type": "image/png",
                  },
                })
                .then((res) => {
                  // console.log("--------",this.reqObj.image)
                  $("#imageModal").modal("hide");
                  this.$refs.submitButton.click();
                });
            } else {
              $("#imageModal").modal("hide");
              this.$swal(
                "Error",
                "Select an image whose size is less than 1 MB",
                "warning"
              );
              event.target.value = [];
            }
          } else {
            $("#imageModal").modal("hide");
            this.$swal(
              "Error",
              "Only jpg/jpeg and png files are allowed!",
              "warning"
            );
            event.target.value = [];
          }
        });
      }
    },
    imageModalController() {
      if (this.isEditable && this.adminChecker()) {
        $("#imageModal").modal("show");
      }
    },

    getPreAssighedUrl() {
      return user.getPreAssighedUrl(this.image_type).then((result) => {
        this.reqObj.image = result.data.data.object_key;
        this.img_save_path = result.data.data.url;
      });
    },
    apply_all(x, y) {
      let ids = {};
      ids["alias"] = x;
      ids["ids"] = y;
      user.UpdateAliasByClient(ids).then((result) => {
        Swal.fire("Updated", result.data.message, "Updated");
      });
      this.GetAssignedAlias();
    },

    validationIndicator(page) {
      this.validator = page;
    },
    trimer(title) {
      let arr = title.split(" ");
      if (arr.length > 30) {
        return (
          arr
            .slice(0, arr.length - 15)
            .toString()
            .replace(/,/g, " ") + "......."
        );
      } else {
        return title;
      }
    },
    adminChecker() {
      let user_role = JSON.parse(localStorage.getItem("user_role"));
      // console.log(user_role);
      let user_id = JSON.parse(localStorage.getItem("current_user"));
      if (user_role == 1 && Number(this.$route.params.id) !== user_id) {
        return true;
      }
    },
    makeEditable() {
      this.isEditable = !this.isEditable;
      this.imageClicked = false;
      this.validator = "";
      this.errorObj = {
        panError: "",
        dobError: "",
        emailError: "",
        passwordError: "",
        p_mobileError: "",
        p_emailError: "",
        p_zipError: "",
        c_zipError: "",
        per_day_minutes_error: "",
        login_slot_error: "",
      };
      this.getUserById(this.$route.params.id);
    },

    nameFormatter(fname, mname, lname) {
      if (fname !== null && lname !== null && mname !== null) {
        return fname + " " + mname + " " + lname;
      }
      if (fname != null && lname == null && mname !== null) {
        return fname + " " + mname;
      }
      if (fname != null && lname != null && mname == null) {
        return fname + " " + lname;
      }
      if (fname == null && lname != null && mname != null) {
        return lname + " " + mname;
      }
      if (fname != null && lname == null && mname == null) {
        return fname;
      }
    },

    // get skills list
    getusersSkills() {
      // console.log(this.$route.params.id);
      return user.getskillbyuser(this.$route.params.id).then((result) => {
        let list = result.data.data;
        this.selected_skills = list
          .map((obj) => {
            return obj.id;
          })
          .sort();
        selected_skills_copy = this.selected_skills;
        // console.log("skills copy---- ",selected_skills_copy);
        // console.log("---------------",this.selected_skills);
      });
    },

    // get skills list
    getSkillsList() {
      user.getskillsDropdown().then((result) => {
        this.skills = result.data.data;
        // console.log("--------",result);
      });
    },
    deleteSkills(id) {
      user.deleteskill(id).then((response) => {
        if (response.status == 200) {
          // this.getusersSkills();
          this.$swal("Success", "Skill deleted successfully", "success").then(
            () => {
              this.getskills();
            }
          );
        } else {
          this.$swal("Error", "Failed to delete skills", "error");
        }
      });
    },
    KRA_Add(data, id) {
      user.addKRA(data, this.$route.params.id).then((result) => {
        if (result.data.code == 200) {
          $("#exampleModal").modal("hide");
          this.$swal("Success", "KRA created successfully...", "success").then(
            () => {
              this.KRA_list();
            }
          );
        }
        if (result.data.code == 400) {
          $("#exampleModal").modal("hide");
          this.$swal("warning", "same data", "warning").then(() => {
            this.KRA_list();
          });
        }
      });
    },

    KRA_list() {
      // console.log("---")
      user.getKRADropdown().then((result) => {
        this.kra_list = result.data.data;
        // console.log("====",result.data.data);
      });
    },

    KRAByUser() {
      user.getKRAbyid(this.$route.params.id).then((result) => {
        let temp = result.data.data;
        // console.log(temp);
        this.selected_kra = temp
          .map((obj) => {
            return obj.id;
          })
          .sort();
        // console.log(this.selected_kra);
        selected_kra_copy = this.selected_kra;
        // console.log("kra copy----- ",selected_kra_copy);
      });
    },
    GetAssignedGoals() {
      // console.log("search",fields);
      user.getGoalsByUser(this.$route.params.id).then((result) => {
        this.goals = [];
        this.goals = result.data.data;
      });
    },

    GetAssignedAlias() {
      user.getAliasByClient(this.$route.params.id).then((result) => {
        this.alias = [];
        this.alias = result.data.data.data;
        this.clients = result.data.data.clients;
      });
    },
    keyValueChecker(array1, array2) {
      let bool = false;
      if (array1.sort().toString() === array2.sort().toString()) {
        return (bool = false);
      } else {
        bool = true;
      }
      return bool;
    },
    styleChanger(pg) {
      this.page = pg;
      if (this.page === "skills" && this.addUser == false) {
        if (
          this.selected_skills.toString() === selected_skills_copy.toString()
        ) {
          this.getusersSkills();
          this.getSkillsList();
        }
      }
      if (this.page === "kra" && this.addUser == false) {
        if (this.selected_kra.toString() === selected_kra_copy.toString()) {
          this.KRAByUser();
          this.KRA_list();
        }
      }
      if (this.page == "goals" && this.addUser == false) {
        this.GetAssignedGoals();
      }
      if (this.page == "assets" && this.addUser == false) {
        this.user_asset_list = null;
        this.get_assign_asset();
        this.getUserAssets(this.$route.params.id);
      }
      if (this.page == "projects" && this.addUser == false) {
        this.projects = null;
        this.getUserProjectList(this.$route.params.id);
      }
    },
    getUserDesignationDropdown() {
      user.getUserDesignationDropdown().then((result) => {
        this.designations = result.data.data;
        // console.log(this.designations);
      });
    },
    getUserTypeDropdown() {
      user.getUserTypeDropdown().then((result) => {
        this.user_type = result.data.data;
        // console.log("user type--------",result)
      });
    },
    loginSlotInput(selectedDates, time, instance) {
      this.reqObj.login_slot = time;
      this.errorObj.login_slot_error = "";
    },

    // validation for first name
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    preventLeadingSpace(evt) {
      // only prevent the keypress if the value is blank
      if (!evt.target.value) evt.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (evt.target.value[0] == " ")
        evt.target.value = evt.target.value.replace(/^\s*/, "");
    },

    getCurrentStatesList() {
      // console.log("current states");
      user.get_statesList(this.reqObj.c_country).then((result) => {
        this.states2 = result.data;
        // console.log("current state",this.states2);
      });
    },

    getCurrentCitiesList() {
      user.get_citiesList(this.reqObj.c_state).then((result) => {
        this.cities2 = result.data;
      });
    },

    getPermanentStatesList() {
      user.get_statesList(this.reqObj.p_country).then((result) => {
        this.states = result.data;
      });
    },
    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getPermanentCitiesList() {
      user.get_citiesList(this.reqObj.p_state).then((result) => {
        // console.log("-----------", result);
        this.cities = result.data;
      });
    },

    allInputCheck() {
      // console.log("add all inputs",this.reqObj);
      let check = true;
      // password validation
      // var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      // this.reqObj.passwordError = "";
      // if ( !regex.test(this.reqObj.password)) {
      //   check = false;
      //   this.reqObj.passwordError = "Please enter valid password";
      // }

      // pan validation
      if (this.reqObj.pan === "" || this.reqObj.pan === null) {
        if (check) {
          check = true;
        }
      } else {
        var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
        this.errorObj.panError = "";
        if (!regex.test(this.reqObj.pan)) {
          check = false;
          this.errorObj.panError = "Please Enter Valid PAN No";
          this.validationIndicator("pinfo");
          this.page = "pinfo";
        }
      }

      // PhoneNumber validation
      // var regex = /^[6-9]\d{2}[2-9]\d{2}\d{4}$/;
      // this.reqObj.p_mobileError = "";
      // if ( !regex.test(this.reqObj.p_mobile)) {
      //   check = false;
      //   this.reqObj.p_mobileError = "Mobile number must be 10 digits starting from 6,7,8,9";
      // }

      // office email validation
      // var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.reqObj.o_email === "" || this.reqObj.o_email === null) {
        if (check) {
          check = true;
        }
      } else {
        var regex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.errorObj.emailError = "";
        if (!regex.test(this.reqObj.o_email)) {
          check = false;
          this.errorObj.emailError = "please enter valid email";
          this.validationIndicator("oinfo");
          this.page = "oinfo";
        }
      }

      // personalemail validation
      if (this.reqObj.p_email === "" || this.reqObj.p_email === null) {
        if (check) {
          check = true;
        }
      } else {
        var regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.errorObj.p_errorError = "";
        if (!regex.test(this.reqObj.p_email)) {
          check = false;
          this.errorObj.p_emailError = "Please enter valid email";
          this.validationIndicator("pinfo");
          this.page = "pinfo";
        }
      }

      // personal Zip code validation
      if (this.reqObj.p_zip === "" || this.reqObj.p_zip === null) {
        if (check) {
          check = true;
        }
      } else {
        var regex = /^\d{6}(-\d{4})?$/;
        this.errorObj.p_zipError = "";
        if (!regex.test(this.reqObj.p_zip)) {
          check = false;
          this.errorObj.p_zipError = "Please enter valid zip code";
          this.validationIndicator("pinfo");
          this.page = "pinfo";
        }
      }

      // current zip code validation
      if (this.reqObj.c_zip === "" || this.reqObj.c_zip === null) {
        if (check) {
          check = true;
        }
      } else {
        var regex = /^\d{6}(-\d{4})?$/;
        this.errorObj.c_zipError = "";
        if (!regex.test(this.reqObj.c_zip)) {
          check = false;
          this.errorObj.c_zipError = "Please enter valid zip code";
          this.validationIndicator("pinfo");
          this.page = "pinfo";
        }
      }
      this.errorObj.login_slot_error = "";
      if (this.reqObj.login_slot == null || this.reqObj.login_slot == "") {
        this.errorObj.login_slot_error = "Please select the login slot";
        this.validationIndicator("pinfo");
        check = false;
      }
      //for checking
      this.errorObj.per_day_minutes_error = "";
      if (
        this.reqObj.per_day_minutes["HH"] == "" &&
        this.reqObj.per_day_minutes["mm"] == ""
      ) {
        this.errorObj.per_day_minutes_error =
          "\tPlease select your per day hours";
        this.validationIndicator("pinfo");
        check = false;
      }

      // birthdate validation
      let currentDate = new Date();
      let birthDate = new Date(this.birthdateformateDate(this.reqObj.dob));
      let difference = currentDate - birthDate;
      let age = Math.floor(difference / 31557600000);
      if (age < 19) {
        check = false;
        this.errorObj.dobError = "you have to be older than 19";
        this.validator = "pinfo";
      }
      if (age > 100) {
        check = false;
        this.errorObj.dobError = "you have to be younger than 100";
        this.validator = "pinfo";
      }

      return check;
    },

    getUserTypeDropdown() {
      user.getUserTypeDropdown().then((result) => {
        this.user_types = result.data.data;
        // console.log("user type--------"this.user_types)
      });
    },
    getTime(min) {
      min = Number(min);
      // console.log(min);
      let hr = min / 60;
      let rhr = Math.floor(hr);
      let minutes = (hr - rhr) * 60;
      // console.log(rhr+":"+minutes);
      return rhr + ":" + minutes;
    },
    getDesignation(id) {
      for (let i of this.designations) {
        if (i.id == id) {
          return i.name;
        }
      }
    },
    getType(id) {
      for (let i of this.user_types) {
        if (i.id == id) {
          return i.name;
        }
      }
    },
    getCurrentStatesList() {
      if (this.reqObj.c_country != null) {
        user.get_statesList(this.reqObj.c_country).then((result) => {
          this.states2 = result.data;
        });
      }
    },

    getCurrentCitiesList() {
      if (this.reqObj.c_state != null) {
        user.get_citiesList(this.reqObj.c_state).then((result) => {
          this.cities2 = result.data;
        });
      }
    },

    getPermanentStatesList() {
      if (this.reqObj.p_country != null) {
        user.get_statesList(this.reqObj.p_country).then((result) => {
          this.states = result.data;
        });
      }
    },
    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getPermanentCitiesList() {
      if (this.reqObj.p_state != null) {
        user.get_citiesList(this.reqObj.p_state).then((result) => {
          this.cities = result.data;
        });
      }
    },
    getUsers() {
      user.getmanageridDropdown(this.reqObj).then((result) => {
        this.users = result.data.data;
      });
    },

    //Functions for data formatting the view model===================

    reportTo(id) {
      for (let i of this.users) {
        if (i.id == id) {
          return i.first_name;
        }
      }
    },
    getRoles(id) {
      let role_name = "";
      if (this.reqObj.user_role != null) {
        this.roles.find((obj) => {
          if (obj.id == id) {
            role_name = obj.name;
          }
        });
        return role_name;
      }
    },
    getStates(id, name) {
      let state_name = "";
      if (this.reqObj.c_state != null && name == "current") {
        this.states2.find((obj) => {
          if (obj.id == id) {
            state_name = obj.statename;
          }
        });
      }
      if (this.reqObj.p_state != null && name == "permanent") {
        this.states.find((obj) => {
          if (obj.id == id) {
            state_name = obj.statename;
          }
        });
      }
      return state_name;
    },
    getCountriesName(id) {
      let country_name = "";
      if (this.reqObj.c_country != null || this.reqObj.p_country != null) {
        this.countries.find((obj) => {
          if (obj.id == id) {
            country_name = obj.name;
          }
        });
        return country_name;
      }
    },
    cityFinder(id, name) {
      let city_name = "";
      if (this.reqObj.p_city != null && name == "permanent") {
        this.cities.find((obj) => {
          if (obj.id == id) {
            city_name = obj.cityname;
          }
        });
      }
      if (this.reqObj.c_city != null && name == "current") {
        this.cities2.find((obj) => {
          if (obj.id == id) {
            city_name = obj.cityname;
          }
        });
      }
      return city_name;
    },

    organizationFinder(id) {
      let organization_name = "";
      if (this.reqObj.organization != null) {
        this.organizations.find((obj) => {
          if (obj.id == id) {
            organization_name = obj.name;
          }
        });
      }
      return organization_name;
    },

    //======================================

    getListOrganizationDropdown() {
      user.getListOrganization().then((result) => {
        this.organizations = result.data.data;
      });
    },
    getmanageridDropdown() {
      user.getmanageridDropdown(this.reqObj).then((result) => {
        // console.log(result);
        this.managers = result.data.data;
      });
    },

    getUserRoleDropdown() {
      user.getUserRoleDropdown(this.reqObj).then((result) => {
        // console.log(result);
        // console.log("user roles",this.reqObj.roles);
        this.roles = result.data.data;
      });
    },
    getUserDesignationDropdown() {
      user.getUserDesignationDropdown().then((result) => {
        this.designations = result.data.data;
      });
    },
    get_countries() {
      if (this.reqObj != null) {
        user.get_countries(this.reqObj).then((result) => {
          this.countries = result.data;
          this.countries2 = result.data;
        });
      }
    },
    getTimeObj(time) {
      const hr = time / 60;
      const rhr = Math.floor(hr);
      const min = (hr - rhr) * 60;
      const rhr_ = (rhr > 9 ? "" : "0") + rhr;
      const min_ = (min > 9 ? "" : "0") + min;
      return { HH: String(rhr_), mm: String(min_) };
    },
    getUserById(id) {
      // user.getUserById(id).then((result) => {
      user.getUserProfile(id).then((result) => {
        // console.log("user view data",result);
        // if(result.data == null){
        //   this.unauthorized = 1

        // }
        // else {
        // console.log(result.data["report_to"]);
        // console.log("report_to",this.reqObj.report_to);
        // console.log("user id is",result.data);
        // console.log(JSON.stringify(result.[0]['id']));
        this.reqObj.first_name = result.data["first_name"];
        this.reqObj.middle_name = result.data["middle_name"];
        this.reqObj.last_name = result.data["last_name"];
        this.reqObj.pan = result.data["pan"];
        this.reqObj.uan_no = result.data["uan_no"];
        this.reqObj.dob = result.data["dob"];
        this.reqObj.doj = result.data["doj"];
        this.reqObj.doe = result.data["doe"];
        this.reqObj.emp_code = result.data["emp_code"];
        this.reqObj.o_email = result.data["o_email"];
        this.reqObj.username = result.data["username"];
        this.reqObj.blood_group = result.data["blood_group"];
        this.reqObj.medical_info = result.data["medical_info"];
        this.reqObj.p_mobile = result.data["p_mobile"];
        this.reqObj.p_email = result.data["p_email"];
        this.reqObj.p_add1 = result.data["p_add1"];
        this.reqObj.image_url = result.data["image_url"];

        this.reqObj.c_country = Number(result.data["c_country"]);
        this.getCurrentStatesList();
        this.reqObj.c_state = Number(result.data["c_state"]);
        this.getCurrentCitiesList();
        this.reqObj.c_city = Number(result.data["c_city"]);

        this.reqObj.p_country = Number(result.data["p_country"]);
        this.getPermanentStatesList();
        this.reqObj.p_state = Number(result.data["p_state"]);
        this.getPermanentCitiesList();
        this.reqObj.p_city = Number(result.data["p_city"]);

        this.reqObj.p_zip = result.data["p_zip"];
        this.reqObj.c_add1 = result.data["c_add1"];
        this.reqObj.c_zip = result.data["c_zip"];
        this.reqObj.report_to = result.data["report_to"];
        this.reqObj.report_to_name = result.data["report_to_name"];
        this.reqObj.login_slot = result.data["login_slot"];
        this.reqObj.user_role = result.data["user_role"];
        this.reqObj.designation_id = result.data["designation_id"];
        this.reqObj.user_type = result.data["user_type"];
        this.reqObj.image = result.data["image"];
        this.reqObj.organization = result.data["organization"];
        this.reqObj.reporting = result.data["reporting"];
        this.reqObj.bank_name = result.data["bank_name"];
        this.reqObj.bank_account_no = result.data["bank_account_no"];
        this.reqObj.esic_no = result.data["esic_no"];
        this.reqObj.ifsc_no = result.data["ifsc_no"];
        this.reqObj.gender = result.data["gender"];
        this.reqObj.emergency_contact_number =
          result.data["emergency_contact_number"];
        this.reqObj.emergency_contact_name =
          result.data["emergency_contact_name"];
        this.reqObj.emergency_contact_relation =
          result.data["emergency_contact_relation"];

        this.reqObj.per_day_minutes = this.getTimeObj(
          result.data["per_day_minutes"]
        );
        this.reqObj.break_minutes = this.getTimeObj(
          result.data["break_minutes"]
        );
        this.reqObj.weekend_strategy = result.data["weekend_strategy"];

        if (this.isEditable == true) {
          userObj_copy = { ...this.reqObj };
          // userObj_copy.per_day_minutes = result.data["per_day_minutes"];
        }
        // if (this.isEditable == false) {
        //   this.reqObj.per_day_minutes = Number(result.data["per_day_minutes"]);
        //   this.reqObj.weekend_strategy = result.data["weekend_strategy"];
        // }
      });
    },
    UserFields() {
      this.isEditable = !this.isEditable;
      this.imageClicked = false;
      this.validator = "";
      this.errorObj = {
        panError: "",
        dobError: "",
        emailError: "",
        passwordError: "",
        p_mobileError: "",
        p_emailError: "",
        p_zipError: "",
        c_zipError: "",
        per_day_minutes_error: "",
        login_slot_error: "",
      };
    },
    hhmmformatter(obj, key) {
      // console.log(obj,key);
      const hh = Number(obj[key]["HH"]);
      const mm = Number(obj[key]["mm"]);
      return hh * 60 + mm;
    },
    verifyOpertations() {
      let change_in_users = false;
      let change_in_skills = false;
      let change_in_kras = false;
      let check = true;
      check = this.allInputCheck();
      if (this.keyValueChecker(this.selected_kra, selected_kra_copy) && check) {
        change_in_kras = true;
        user.addKRA(this.selected_kra, this.$route.params.id).then((result) => {
          this.KRAByUser();
          this.isEditable = false;
        });
      }
      if (
        this.keyValueChecker(this.selected_skills, selected_skills_copy) &&
        check
      ) {
        change_in_skills = true;
        user
          .addskill(this.selected_skills, this.$route.params.id)
          .then((result) => {
            this.getusersSkills();
            this.isEditable = false;
          });
      }
      const temp_login = this.reqObj.per_day_minutes;
      let temp = { ...this.reqObj };
      // console.log(temp);
      // const hh = Number(temp.per_day_minutes["HH"]);
      // const mm = Number(temp.per_day_minutes["mm"]);
      temp.per_day_minutes = this.hhmmformatter(temp, "per_day_minutes");
      temp.break_minutes = this.hhmmformatter(temp, "break_minutes");
      // console.log("---- temp ",temp);
      // console.log("---- userobj ",userObj_copy);
      this.addUser == true ? (userObj_copy = {}) : "";
      if (
        check == true &&
        Object.values(userObj_copy).toString() !==
          Object.values(temp).toString()
      ) {
        // console.log("im called");
        if (this.reqObj.organization === "") {
          this.reqObj.organization = null;
        }
        this.reqObj.per_day_minutes = this.hhmmformatter(
          this.reqObj,
          "per_day_minutes"
        );
        this.reqObj.break_minutes = this.hhmmformatter(
          this.reqObj,
          "break_minutes"
        );
        this.reqObj.doj = new Date(this.reqObj.doj).toISOString().slice(0, 10);
        // this.reqObj.per_day_minutes=Number(this.reqObj.per_day_minutes);
        // this.reqObj.weekend_strategy=Number(this.reqObj.weekend_strategy);
        if (this.addUser == true) {
          user
            .newUserAdd(this.reqObj)
            .then((result) => {
              if (result.data.code == "200") {
                // this.getUsers();
                Swal.fire("Success", "User Added succesfully....!", "success");
                this.$router.push({ name: "Users" });
              } else {
                this.reqObj.per_day_minutes = temp_login;
                Swal.fire({
                  padding: "1rem",
                  html: result.data.message,
                  icon: "error",
                  showConfirmButton: false,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                title: "Failed",
                text: err.response.data.detail,
                icon: "error",
              });
            });
        } else {
          // console.log("in else");
          user
            .updatetheuser(this.editid, this.reqObj)
            .then((result) => {
              if (result.data.code == "200") {
                change_in_users = true;
                // console.log("from api")
                this.isEditable = false;
                this.selected_kra = [];
                selected_kra_copy = [];
                this.selected_skills = [];
                selected_skills_copy = [];
                // Swal.fire("Success",result.data.message,"success").then(()=>{
                // });
                // alert(result.data.message);
                // this.$router.push('/prof/'+this.$route.params.id);
                if (
                  change_in_users === true ||
                  change_in_skills === true ||
                  change_in_kras === true
                ) {
                  // console.log("with in if")
                  Swal.fire(
                    "Success",
                    "User details updated succesfully....!",
                    "success"
                  );
                  this.getUserById(this.$route.params.id);
                  return true;
                } else {
                  // console.log("with in else")
                  Swal.fire("Failed", "User data already exist...", "error");
                  return true;
                }
              } else {
                // alert(result.data.message);
                Swal.fire("Error", result.data.message, "error").then(() => {
                  this.isEditable = false;
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                title: "Failed",
                text: err.response.data.detail,
                icon: "error",
              });
            });
        }
        return check;
      }
      // console.log("after if")
      if (
        (change_in_users === true && check) ||
        (change_in_skills === true && check) ||
        (change_in_kras === true && check)
      ) {
        // console.log("with in if")
        Swal.fire(
          "Success",
          "User details updated succesfully....!",
          "success"
        );
        this.isEditable = !this.isEditable;
        this.getUserById(this.$route.params.id);
      } else if (temp.image_url == undefined && this.imageClicked === true) {
        Swal.fire("Failed", "User profile image is already empty", "error");
        this.imageClicked = false;
      } else if (check) {
        // console.log("with in else")
        Swal.fire("Failed", "User data already exist...", "error");
        // this.isEditable=!this.isEditable;
      }
    },
    birthdateformateDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formateValueDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    logout() {
      this.logoutUser();
      this.$nextTick(() => {
        this.getCurrentUser();
      });
    },
  },
  destroyed() {
    localStorage.removeItem("assets-list");
  },
  mounted() {
    this.setCurrentPage("Users");
    // console.log(this.currentUser);
    // //this.getUserPermissions();
    if (this.permissions.includes("users.read")) {
      // this.getusersSkills();
      // this.getskills();
      // this.KRA_list();
      this.getUsers();
      // //this.getUserPermissions();
      // console.log(this.getCurrentUser);
      // console.log("user");
      // const usr_role=localStorage.getItem('user_role');
      // console.log("----------",usr_role);
      // if(usr_role==1){
      if (this.currentUser.user_role == 1) {
        localStorage.setItem("assets-list", JSON.stringify([]));
      }
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        this.editid = this.$route.params.id;
        this.getUserById(this.$route.params.id);
      } else if (
        typeof this.$route.params.id == "undefined" ||
        this.$route.params.id == 0
      ) {
        this.addUser = true;
        this.UserFields();
      }
      // this.getUserById(90);
      this.getListOrganizationDropdown();
      this.getmanageridDropdown();
      this.getUserRoleDropdown();
      this.get_countries();
      this.getUserDesignationDropdown();
      this.getUserTypeDropdown();
      if (this.$route.query.tab) {
      let tab = this.$route.query.tab;
       if (['assets'].includes(tab)) { 
      this.styleChanger(tab);
      } 
  }  
    } else {
      this.$router.push("/unauthorized-page");
    }
    $("#assetModal").on("hidden.bs.modal", this.resetModalHandler);
  },
};
</script>

<style scoped>
#cross {
  cursor: pointer;
  font-weight: bolder;
  font-size: 1.2rem;
}

#cross:hover {
  color: red;
  transition: 0.2s ease-in-out;
}

#profile-hover {
  height: 150px;
  width: 150px;
  background-color: lightgray;
  opacity: 50%;
  cursor: pointer;
}

#profile-hover:hover {
  background-color: lightgray;
  opacity: 80%;
}

.mandatory:after {
  content: "*";
  padding: 0px 2px;
  color: red;
}

::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}

::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
